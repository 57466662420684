
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .min-h-screen {
    min-height: 100vh;
  }

  textarea {
    resize: none;
  }

  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

  html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', sans-serif;
  }